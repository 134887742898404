export type ICognitoConfig = {
    authorizationUrl: string
    tokenUrl: string
    revokeUrl: string
    userInfo: string
    responseType: string
    clientId: string
    scope: string
    redirectUri: string
}

const config: ICognitoConfig = {
    authorizationUrl:
        'https://jrgn.auth.eu-west-1.amazoncognito.com/oauth2/authorize',
    tokenUrl: 'https://jrgn.auth.eu-west-1.amazoncognito.com/oauth2/token',
    revokeUrl: 'https://jrgn.auth.eu-west-1.amazoncognito.com/oauth2/revoke',
    userInfo: 'https://jrgn.auth.eu-west-1.amazoncognito.com/oauth2/userInfo',
    responseType: 'code',
    clientId: '3he70dd6e5v4uhofdee6fkjsgs',
    scope: 'openid+profile+email',
    redirectUri:
        process.env.NODE_ENV === 'production'
            ? 'https://trening.jrgn.no/'
            : 'http://localhost:3000',
}

export default config
