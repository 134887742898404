import React, { useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { GridColDef } from '@mui/x-data-grid/models'
import { useNavigate } from 'react-router-dom'

type CustomGridProps = {
    data?: any
    overLay: React.ReactNode
    columns: GridColDef[]
    navigateTo: string
}

const CustomGrid: React.FC<CustomGridProps> = ({
    data,
    overLay,
    columns,
    navigateTo,
}) => {
    const [pageSize, setPageSize] = useState<number>(10)
    const navigate = useNavigate()
    return (
        <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
                <div style={{ height: 600, width: 'inherit' }}>
                    {data.length > 0 ? (
                        <DataGrid
                            style={{ margin: 15 }}
                            rows={data}
                            columns={columns}
                            pageSize={pageSize}
                            rowsPerPageOptions={[5, 10, 15]}
                            onPageSizeChange={setPageSize}
                            disableSelectionOnClick
                            onRowClick={(params) => {
                                navigate(`/${navigateTo}/` + params.row.id)
                            }}
                        />
                    ) : (
                        overLay
                    )}
                </div>
            </div>
        </div>
    )
}

export default CustomGrid
