// @ts-nocheck
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import DatePicker from '@mui/lab/DatePicker'
import { Controller, useForm } from 'react-hook-form'
import { FormControl } from '@mui/material'
import moment, { Moment } from 'moment'
import axios from 'axios'
import apiConfig from '../apiConfig'

const style = {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: 'auto',
    boxShadow: 24,
    p: 4,
}

interface FormInput {
    name: string
    date: Moment
}

const NewWorkout = () => {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const { handleSubmit, control } = useForm<FormInput>({
        defaultValues: {
            name: '',
            date: moment(),
        },
    })

    const mutation = useMutation(
        'createWorkout',
        (workout) => axios.post(`${apiConfig.trainingApiUrl}/workout`, workout),
        {
            onSuccess: (data) => {
                setOpen(false)
                navigate('/workout/' + data.data.id)
            },
            onError: (error) => setOpen(false),
        }
    )

    const onSubmit = (data: FormInput) => mutation.mutate(data)

    const handleClose = () => setOpen(false)

    return (
        <React.Fragment>
            <FormControl onSubmit={onSubmit}>
                <Button
                    variant="contained"
                    endIcon={<AddIcon />}
                    style={{ margin: 15 }}
                    onClick={() => setOpen(true)}
                >
                    New workout
                </Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            style={{ margin: 10 }}
                        >
                            Create workout
                        </Typography>
                        <div style={{ margin: 10 }}>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        label="Name"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </div>
                        <div style={{ margin: 10 }}>
                            <Controller
                                name="date"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <DatePicker
                                        label="Date of workout"
                                        value={value}
                                        onChange={onChange}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                )}
                            />
                        </div>
                        <div style={{ margin: 10 }}>
                            <Button onClick={handleSubmit(onSubmit)}>
                                Submit
                            </Button>
                        </div>
                    </Box>
                </Modal>
            </FormControl>
        </React.Fragment>
    )
}

export default NewWorkout
