export type ApiConfig = {
    trainingApiUrl: string
}

const apiConfig: ApiConfig = {
    trainingApiUrl:
        process.env.NODE_ENV === 'production'
            ? 'https://api.jrgn.no/training/v1'
            : 'http://localhost:8080/training/v1',
}

export default apiConfig
