import * as React from 'react'
import { GridColDef } from '@mui/x-data-grid/models'
import Stack from '@mui/material/Stack'
import moment from 'moment'
import CustomGrid from './CustomGrid'
import NewWorkout from './NewWorkout'

const workoutColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Name', width: 150 },
    {
        field: 'date',
        headerName: 'Date',
        type: 'date',
        width: 150,
        valueFormatter: (params) => {
            if (typeof params.value === 'number') {
                return moment.unix(params.value / 1000).format('L')
            }
        },
        valueGetter: (params) => {
            if (typeof params.value === 'number') {
                return moment.unix(params.value / 1000).format('L')
            }
        },
    },
]

const NoRowsOverlay = () => (
    <Stack justifyContent="center" alignItems="center">
        Got no workouts!
    </Stack>
)

const WorkoutGrid: React.FC<{ data?: any }> = ({ data }) => {
    return (
        <div>
            <NewWorkout />
            <CustomGrid
                data={data}
                columns={workoutColumns}
                overLay={<NoRowsOverlay />}
                navigateTo="workout"
            />
        </div>
    )
}

export default WorkoutGrid
