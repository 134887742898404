import { useQuery } from 'react-query'
import axios from '../api/axios'
import apiConfig from '../apiConfig'
import ExerciseGrid from '../components/ExerciseGrid'

const { trainingApiUrl } = apiConfig

const Exercises = () => {
    const { isLoading, isError, error, data } = useQuery(
        'exercises',
        () => axios.get(`${trainingApiUrl}/exercises`),
        { retry: false }
    )

    if (isLoading) return <div>loading...</div>
    if (isError) {
        console.log(error)
        // @ts-ignore
        return <div>Error: ${error.message}</div>
    }

    return <ExerciseGrid data={data?.data} />
}

export default Exercises
