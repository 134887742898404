import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchTokens, logout } from '../../api/token'
import jwt_decode from 'jwt-decode'
import moment from 'moment'

export interface AccessTokenInfo {
    auth_time: number
    client_id: string
    'cognito:groups': string[]
    exp: number
    iat: number
    iss: string
    jti: string
    origin_jti: string
    scope: string
    sub: string
    token_use: string
    username: string
    version: number
}

export interface AuthState {
    accessToken: string | null
    idToken: string | null
    refreshToken: string | null
    userInfo: AccessTokenInfo | null
    status: 'authenticating' | 'loggedIn' | 'loggedOut' | 'failed' | 'expired'
    tokenExpiresAt: number | undefined
}

const getInitialStatus = () => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
        const exp = jwt_decode<AccessTokenInfo>(accessToken).exp
        const tokenExpiration = moment.unix(exp)
        const now = moment()
        if (tokenExpiration.isAfter(now)) {
            return 'loggedIn'
        }
    }
    return 'loggedOut'
}

const getInitialExpiration = () => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
        return jwt_decode<AccessTokenInfo>(accessToken).exp
    }
    return moment.unix(10).seconds()
}

const initialState: AuthState = {
    accessToken: localStorage.getItem('accessToken'),
    idToken: localStorage.getItem('idToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    userInfo: localStorage.getItem('accessToken')
        ? jwt_decode(<string>localStorage.getItem('accessToken'))
        : null,
    status: getInitialStatus(),
    tokenExpiresAt: getInitialExpiration(),
}

export const tokensAsync = createAsyncThunk(
    'auth/fetchTokens',
    async (code: string) => await fetchTokens(code)
)

export const logoutAsync = createAsyncThunk(
    'auth/logout',
    async (refreshToken: string | null) => await logout(refreshToken)
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(tokensAsync.pending, (state) => {
                state.status = 'authenticating'
            })
            .addCase(tokensAsync.rejected, (state) => {
                state.status = 'failed'
            })
            .addCase(tokensAsync.fulfilled, (state, action) => {
                state.status = 'loggedIn'
                const { accessToken, idToken, refreshToken } = action.payload
                const decodedJwtToken = jwt_decode<AccessTokenInfo>(accessToken)

                state.userInfo = decodedJwtToken
                state.tokenExpiresAt = decodedJwtToken.exp
                state.accessToken = accessToken
                state.idToken = idToken
                state.refreshToken = refreshToken

                localStorage.setItem('accessToken', accessToken)
                localStorage.setItem('idToken', idToken)
                localStorage.setItem('refreshToken', refreshToken)
            })

        builder
            .addCase(logoutAsync.pending, (state, action) => {
                state.status = 'authenticating'
            })
            .addCase(logoutAsync.rejected, (state, action) => {
                state.status = 'failed'
            })
            .addCase(logoutAsync.fulfilled, (state, action) => {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('idToken')
                localStorage.removeItem('refreshToken')
                state.status = 'loggedOut'
                state.tokenExpiresAt = 10
                state.idToken = null
                state.accessToken = null
                state.refreshToken = null
            })
    },
})

export default authSlice.reducer
