import React from 'react'
import { GridColDef } from '@mui/x-data-grid/models'
import Stack from '@mui/material/Stack'
import CustomGrid from './CustomGrid'
import NewExercise from './NewExercise'

const exerciseColDefs: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'metric', headerName: 'Metric', width: 150 },
    { field: 'description', headerName: 'Description', width: 150 },
    { field: 'isBodyWeight', headerName: 'Bodyweight', width: 150 },
    { field: 'type', headerName: 'Type', width: 150 },
]

const NoRowsOverlay = () => (
    <Stack justifyContent="center" alignItems="center">
        Got no exercises!
    </Stack>
)

const ExerciseGrid: React.FC<{ data?: any }> = ({ data }) => {
    return (
        <div>
            <NewExercise />
            <CustomGrid
                data={data}
                overLay={<NoRowsOverlay />}
                columns={exerciseColDefs}
                navigateTo={'exercise'}
            />
        </div>
    )
}

export default ExerciseGrid
