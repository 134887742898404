import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
// @ts-ignore
import DateAdapter from '@mui/lab/AdapterMoment'
import { store } from './app/store'
import { Provider } from 'react-redux'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { QueryClient, QueryClientProvider } from 'react-query'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

const queryClient = new QueryClient()
ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                        <App />
                    </LocalizationProvider>
                </QueryClientProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
