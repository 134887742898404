import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { Controller, useForm } from 'react-hook-form'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import axios from 'axios'
import apiConfig from '../apiConfig'

const style = {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: 'auto',
    boxShadow: 24,
    p: 4,
}

interface FormInput {
    name: string
    metric: 'KG' | 'seconds'
    description: string
    isBodyWeight: boolean
    type: 'Static' | 'Dynamic'
}

const NewExercise = () => {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    const { handleSubmit, control } = useForm<FormInput>({
        defaultValues: {
            name: 'Gandalf',
            metric: 'KG',
            description: 'Doing my shit',
            isBodyWeight: true,
            type: 'Dynamic',
        },
    })

    const mutation = useMutation(
        'createExercise',
        (exercise) =>
            axios.put(`${apiConfig.trainingApiUrl}/exercise`, exercise),
        {
            onSuccess: (data) => {
                setOpen(false)
                navigate('/exercise/' + data.data.id)
            },
            onError: (error) => setOpen(false),
        }
    )

    const onSubmit = (data: any) => mutation.mutate(data)

    const handleClose = () => setOpen(false)

    return (
        <React.Fragment>
            <FormControl onSubmit={onSubmit}>
                <Button
                    variant="contained"
                    endIcon={<AddIcon />}
                    style={{ margin: 15 }}
                    onClick={() => setOpen(true)}
                >
                    New exercise
                </Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            style={{ margin: 10 }}
                        >
                            Create exercise
                        </Typography>
                        <div style={{ margin: 10 }}>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        label="Name"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </div>
                        <div style={{ margin: 10 }}>
                            <Controller
                                name="metric"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <div>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="KG"
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel
                                                value="KG"
                                                control={<Radio />}
                                                label="KG"
                                            />
                                            <FormControlLabel
                                                value="seconds"
                                                control={<Radio />}
                                                label="Seconds"
                                            />
                                        </RadioGroup>
                                    </div>
                                )}
                            />
                        </div>
                        <div style={{ margin: 10 }}>
                            <Controller
                                name="description"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        label="Description"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </div>
                        <div style={{ margin: 10 }}>
                            <Controller
                                name="isBodyWeight"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        label="Bodyweight"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </div>
                        <div style={{ margin: 10 }}>
                            <Controller
                                name="type"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <div>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="Dynamic"
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel
                                                value="Dynamic"
                                                control={<Radio />}
                                                label="Dynamic"
                                            />
                                            <FormControlLabel
                                                value="Static"
                                                control={<Radio />}
                                                label="Static"
                                            />
                                        </RadioGroup>
                                    </div>
                                )}
                            />
                        </div>
                        <div style={{ margin: 10 }}>
                            <Button onClick={handleSubmit(onSubmit)}>
                                Submit
                            </Button>
                        </div>
                    </Box>
                </Modal>
            </FormControl>
        </React.Fragment>
    )
}

export default NewExercise
