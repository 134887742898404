import config from '../cognitoConfig'
import axios from 'axios'
import { AxiosRequestConfig } from 'axios'

export const fetchTokens = async (
    code: string
): Promise<{ accessToken: string; idToken: string; refreshToken: string }> => {
    const { tokenUrl, clientId, redirectUri } = config
    const requestConfig: AxiosRequestConfig = {
        method: 'POST',
        url: tokenUrl,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: `grant_type=authorization_code&client_id=${clientId}&code=${code}&redirect_uri=${redirectUri}`,
    }
    return axios
        .create()(requestConfig)
        .then((res) => {
            return {
                accessToken: res.data.access_token,
                idToken: res.data.id_token,
                refreshToken: res.data.refresh_token,
            }
        })
}

export const logout = async (refreshToken: string | null): Promise<any> => {
    const { revokeUrl, clientId } = config
    const requestConfig: AxiosRequestConfig = {
        method: 'POST',
        url: revokeUrl,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
        },
        data: `token=${refreshToken}&client_id=${clientId}`,
    }
    return axios
        .create()(requestConfig)
        .then((res) => res.data)
}
