import WorkoutGrid from '../components/WorkoutGrid'
import axios from '../api/axios'
import { useQuery } from 'react-query'
import apiConfig from '../apiConfig'

const { trainingApiUrl } = apiConfig

export default () => {
    const { isLoading, isError, error, data } = useQuery(
        'workouts',
        () => axios.get(`${trainingApiUrl}/workouts`),
        { retry: false }
    )

    if (isLoading) return <div>loading...</div>
    if (isError) {
        console.log(error)
        // @ts-ignore
        return <div>Error: ${error.message}</div>
    }

    return <WorkoutGrid data={data?.data} />
}
