import React, { useEffect } from 'react'
import config from './cognitoConfig'
import { tokensAsync } from './redux/auth/authSlice'
// @ts-ignore
import OAuth2Login from 'react-simple-oauth2-login'
import './App.css'
import { useAppDispatch } from './app/hooks'
import { useSelector } from 'react-redux'
import { RootState } from './app/store'
import AuthenticatedApp from './components/AuthenticatedApp'

const onFailure = (response: any) => console.error(response)

function App() {
    const authState = useSelector((state: RootState) => state.auth)
    const dispatch = useAppDispatch()
    const onSuccess = (response: any) => dispatch(tokensAsync(response.code))

    if (authState.status === 'loggedIn') {
        return (
            <div className="App">
                <AuthenticatedApp />
            </div>
        )
    }

    if (authState.status === 'authenticating') {
        return <div className="App">loading...</div>
    }

    return (
        <div className="App">
            <OAuth2Login
                authorizationUrl={config.authorizationUrl}
                responseType={config.responseType}
                clientId={config.clientId}
                redirectUri={config.redirectUri}
                scope={config.scope}
                className="loginButton"
                onSuccess={onSuccess}
                onFailure={onFailure}
            />
        </div>
    )
}

export default App
