import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Analysis from '../routes/Analysis'
import Workouts from '../routes/Workouts'
import { ResponsiveAppBar } from './MenuAppBar'
import Workout from './Workout'
import Exercises from '../routes/Exercises'
import Exercise from './Exercise'

const AuthenticatedApp = () => {
    return (
        <React.Fragment>
            <ResponsiveAppBar />
            <Routes>
                <Route path={'/'} element={<Workouts />} />
                <Route path={'/workouts'} element={<Workouts />} />
                <Route path={'/exercises'} element={<Exercises />} />
                <Route path={'/analysis'} element={<Analysis />} />
                <Route path={'/workout/:id'} element={<Workout />} />
                <Route path={'/exercise/:id'} element={<Exercise />} />
                <Route path={'*'} element={<div>404 NOT FOUND</div>} />
            </Routes>
        </React.Fragment>
    )
}

export default AuthenticatedApp
