import axios from 'axios'

axios.interceptors.request.use(
    function (config) {
        // @ts-ignore

        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }

        return config
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    }
)

export default axios
